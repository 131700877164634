import React from "react"
import Layout from "../../components/layout"
import { SEO } from "../../components/seo"
import styled from "styled-components"
import AboutSection from "../../components/organisms/HomePage/AboutSection"
import JobOffers from "../../components/molecules/JobOffers"
import WithUs from "../../components/organisms/Career/WithUs"

const StyledContent = styled.section`
  font-family: ${props => props.theme.fonts.primary};
`

const Page = props => {
  const ACF = props.pageContext.ACF_career
  const SEO = props.pageContext.seo


  return (
    <Layout seoData={SEO} seoUrl={props.pageContext.uri} overflow='hidden'>
        <AboutSection
            content={ACF.bannerText}
            buttonText="Oferty pracy!"
            buttonLink="#oferty"
            buttonColor="#000"
            secondButtonLink='#pracujZNami'
            secondButtonText="Dlaczego GrupaTAKA"
            secondButtonHover="#000"
            secondButtonColor="#F19206"
            class="about__content align-center"
            minHeight="100vh"
        />
        <JobOffers />
        <WithUs
        textblok={ACF.why}
        />

    </Layout>
  )
}
export default Page


export const Head = props => {
  return (
   <SEO title={props.pageContext.seo.title} description={props.pageContext.seo.opengraphDescription} image={props.pageContext.seo?.opengraphImage?.localFile.childImageSharp.fixed.src} pathname={props.pageContext.seo.opengraphUrl} />
  )
}


