import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"

import Container from "../../components/container"
import GTButton from "../../components/atoms/Button"
import SectionTitle from "../../components/atoms/SectionTitle"

import { StyledJobOffers } from "./JobOffers.style"

const JobOffers = props => {
  const data = useStaticQuery(graphql`
    query JobOffersMyQuery {
        allWpOfertaPracy {
            nodes {
                title
                uri
                ACF_jobOffer {
                  range
                }
            }
        }
    }
  `)


  let OffersItems = '';

  if (data.allWpOfertaPracy.nodes.length !== 0 ) {
     OffersItems = data.allWpOfertaPracy.nodes?.map(
      (item, index) => {
        console.log(item)

        return (
          <div key={index} className="jobs__single">
              <div className="jobs__name">{item.title}</div>
              <div className="jobs__salary">{item.ACF_jobOffer.range}</div>
              <div className="jobs__aply">
                <GTButton
                text="Aplikuj"
                anchor={item.uri.replace('/oferty_pracy/', '/kariera/')}
                fontWeight="700"
                hoverColor='#000'
                />
              </div>
          </div>
        )
      }
    )
  } else {
    OffersItems = 'Nie ma ofert pracy'
  }



  return (
    <StyledJobOffers id='oferty'>
      <Container>
        <SectionTitle text="Oferty_" textBold="pracy" color="#000" />
        <div className="jobs__wrapper">{OffersItems}</div>
      </Container>
    </StyledJobOffers>
  )
}
export default JobOffers
