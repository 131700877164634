import React from "react"

import styled from "styled-components"
import Container from "../../container"
import GTButton from "../../atoms/Button"

import { StaticImage } from "gatsby-plugin-image"
import SectionTitle from "../../atoms/SectionTitle"

const StyledWithUsSection = styled.section`
  padding: 2rem 0 0rem;
  position: relative;

    .sectionTitle {
        margin-bottom: 3rem;
        display: flex;
        flex-direction: column;

        @media ${props => props.theme.sizes.laptop} {
          flex-direction: row;
        }
    }

    .GroupImage {
        margin: 0;
    }
    .whyText {
        color: #000;
        font-size: 1.2rem;
        font-family: ${props => props.theme.fonts.secondary};
        font-weight: 300;

        /* @media ${props => props.theme.sizes.laptop} {
          max-width: 70%;
        } */
    }

`

const WithUs = props => {
  console.log(props.content)
  return (
    <StyledWithUsSection id='pracujZNami'>
        <Container>
            <SectionTitle text="Dlaczego_" textBold="GrupaTAKA?" color="#000" />
            <div className="whyText" dangerouslySetInnerHTML={{ __html: props.textblok }} />
            <figure className="GroupImage">
                <StaticImage
                    src="../../../../content/assets/01GT.jpg"
                    alt="Zdjęcie grupowe GrupaTaka"
                    title="Zdjęcie grupowe GrupaTaka"
                    placeholder="blurred"
                />
            </figure>
        </Container>
    </StyledWithUsSection>
  )
}
export default WithUs
