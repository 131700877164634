import styled from "styled-components"

export const StyledJobOffers = styled.section`
    font-family: ${props => props.theme.fonts.primary};
    margin-top: 8rem;

    @media ${props => props.theme.sizes.tablet} {
        margin-top: 1rem;
    }

    .sectionTitle {
        margin-bottom: 2.5rem;
    }
    .jobs {

        &__wrapper {
            padding: 1.5rem 0 1rem;

            @media ${props => props.theme.sizes.tablet} {
                padding: 1.5rem 0 3rem;
            }
        }
        &__single {
            display: flex;
            flex-direction: column;
            text-align: left;
            width: 100%;
            padding: 3rem 2rem;
            border: solid .25rem #F5F5F5;
            margin-bottom: 2.5rem;
            transition-property: box-shadow,border;
            transition-duration: 0.3s;

            @media ${props => props.theme.sizes.laptop} {
                display: grid;
                grid-template-columns: 5fr 3fr 2fr;
                column-gap: 1.5rem;
                padding: 5rem 2.5rem;
            }

            &:hover {
                box-shadow: 0.5rem 0.5rem 0.75rem 0 rgb(75 75 75 / 12%);
                border: solid .1rem #fff;
                transition-property: box-shadow,border;
                transition-duration: 0.3s;
            }
        }
        &__name {
            font-size: 1.4rem;
            font-weight: 700;
            color: ${props => props.theme.colors.primary};

            @media ${props => props.theme.sizes.laptop} {
                font-size: 1.7rem;
            }
        }
        &__salary {
            font-size: 1.4rem;
            font-weight: 600;
            padding: 2rem 0;

            @media ${props => props.theme.sizes.laptop} {
                padding: 0;
            }
        }
        &__aply {
            display: flex;
            align-items: center;
            justify-content: left;

            @media ${props => props.theme.sizes.laptop} {
                align-items: center;
            justify-content: center;
            }

            a {
                font-size: 1.4rem;
            }
        }
    }

`

