import React from "react"

import styled from "styled-components"
import Container from "../../Container"
import GTButton from "../../atoms/Button"

const StyledAboutSection = styled.section`
  padding: 2rem 0 0rem;
  position: relative;

  @media ${props => props.theme.sizes.laptop} {
    padding: 0 0 6rem;
    min-height: ${props => props.minHeight || "unset"};
    display: flex;
    align-items: center;
  }

  .about__content {
    font-family: ${props => props.theme.fonts.primary};
    font-size: 1.5rem;
    color: ${props => props.theme.colors.primary};
    font-weight: 800;
    text-align: center;

    @media ${props => props.theme.sizes.tablet} {
      font-size: 1.8rem;
      padding: 0 10%;
    }
    @media ${props => props.theme.sizes.laptop} {
      font-size: 2.2rem;
      padding: 0;
    }

    @media ${props => props.theme.sizes.laptopL} {
      font-size: 3rem;
      text-align: left;
      padding: 0;
    }

    h1 {
      margin: 0;
      font-size: unset;
      font-weight: unset;
      color: unset;
      line-height: 1.4;
      color: ${props => props.theme.colors.text};
    }

    &.align-center {
      text-align: center;
    }

    p {
      line-height: 1.4;
    }

    .black {
      color: ${props => props.theme.colors.text};
    }
  }
  .secondButton {
    padding-bottom: 6rem;
  }
  .button {
    text-align: right;

    &::last-of-type {
      background: yellow;
    }
  }
`

const AboutSection = props => {

  return (
    <StyledAboutSection minHeight={props.minHeight}>
      <Container>
        <div
          className={props.class}
          dangerouslySetInnerHTML={{
            __html: props.content,
          }}
        />

          <GTButton
            anchor={props.buttonLink}
            text={props.buttonText}
            fontSize="2rem"
            fontWeight="800"
            color={props.buttonColor}
            hoverColor="#F19206"
            display={props.display}
          />
          <GTButton
            anchor={props.secondButtonLink}
            text={props.secondButtonText}
            fontSize="2rem"
            fontWeight="800"
            color={props.secondButtonColor}
            hoverColor={props.secondButtonHover}
            display={props.secondDisplay}
          />

      </Container>
    </StyledAboutSection>
  )
}
export default AboutSection
